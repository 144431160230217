<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout
      v-if="hasAction('view_user') || isAdmin() || isSuperAdmin() || isModerator() || isAccountant()"
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    v-model="userSearch.searchText"
                    :label="$t('common.search')"
                    clearable
                    @keydown.enter="onSearch($event)"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch($event)"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="$t('product.title')"
          color="green"
          flat
          full-width
        >
          <template>
            <v-btn
              v-if="hasAction('create_user') || isAdmin() || isSuperAdmin() || isModerator() || isAccountant()"
              color="success"
              dark
              style="float: right;"
              @click="onAddNewProduct(null)"
            >
              {{ $t('common.add') }}
            </v-btn>
            <div style="float: right;">
              <v-menu
                offset-y
                transition="slide-y-transition"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="indigo"
                    dark
                    v-on="on">
                    <v-icon class="mr-2">mdi-printer</v-icon>
                    <span style="text-transform: none;">{{
                      $t('prepaidCard.printCard')
                    }}</span>
                  </v-btn>
                </template>
                <v-card>
                  <!-- Print selected -->
                  <v-list>
                    <v-list-tile @click="onShowModalSelectInfo">
                      <v-list-tile-action>
                        <v-icon
                          color="primary">mdi-select</v-icon>
                      </v-list-tile-action>
                      <v-list-tile-title>{{ $t('prepaidCard.printCardSelected') }}</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-card>
              </v-menu>
            </div>
          </template>
          <v-data-table
            v-if="hasAction('view_user') || isAdmin() || isSuperAdmin() || isModerator() || isAccountant()"
            :no-data-text="$t('common.noDataAvailable')"
            :headers="userHeaders"
            :items="productList"
            :single-select="true"
            v-model="selected"
            hide-actions
            select-all
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              v-slot:items="props"
            >
              <td>
                <v-checkbox
                  v-model="props.selected"
                  primary
                  hide-details />
              </td>
              <td style="white-space: nowrap; width: 5%;">{{ props.item.stt }}</td>
              <td
                class="cursor-pointer"
                @mouseover="onHoverEmail(props.item)"
                @mouseleave="onLeaveEmail(props.item)"
              >
                <div style="display: flex">
                  <div class="product-img-div">
                    <img
                      v-if="props.item.img !== null && props.item.img !== ''"
                      :src="props.item.img"
                      class="product-img-class"
                      alt="Product-img">
                  </div>
                  <div style="padding-left: 5px">
                    <span
                      class="pb-1"
                      style="border-bottom: 1px dashed #5cb860">{{ props.item.name }}</span>
                    <div
                      v-show="props.item.isShowTool"
                      class="mt-1"
                      style="display: flex; white-space: nowrap">
                      <span
                        class="text-success hover-detail-text"
                        @click="onShowViewProduct(props.item)">{{ $t('users.viewDetail') }}</span>
                      <span
                        v-if="isAdmin() || isSuperAdmin() || isAccountant()"
                        class="mx-2">|</span>
                      <span
                        v-if="isAdmin() || isSuperAdmin() || isAccountant()"
                        class="hover-delete-text"
                        style="color: blue;"
                        @click="onShowPrintProduct(props.item)">{{ $t('common.print') }}</span>
                      <span
                        v-if="(hasAction('delete_user') && props.item.id != GET_CURRENT_ACCOUNT.id) || isAdmin() || isSuperAdmin() || isAccountant()"
                        class="mx-2">|</span>
                      <span
                        v-if="(hasAction('delete_user') && props.item.id != GET_CURRENT_ACCOUNT.id) || isAdmin() || isSuperAdmin() || isAccountant()"
                        class="hover-delete-text"
                        style="color: red;"
                        @click="onShowDeleteProduct(props.item.id)">{{ $t('common.delete') }}</span>
                    </div>
                  </div>
                </div>
              </td>
              <td>{{ props.item.productPoint }}</td>
              <td style="width: 35%; white-space: pre-wrap;">{{ props.item.description }}</td>
              <td class="text-xs-center">
                <v-menu
                  bottom
                  left
                  offset-y
                  transition="slide-y-transition">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :loading="props.item.isLoading"
                      class="mx-2"
                      fab
                      small
                      color="success"
                      style="color: #ffffff;"
                      v-on="on"
                    >
                      <v-icon dark>mdi-cursor-move</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-list dense>
                      <v-list-tile
                        v-for="(action, index) in moveActionList"
                        :key="action + index"
                        @click="clickMoveQuestion(action, props.item)"
                      >
                        <v-list-tile-title v-text="$t(action.text)" />
                      </v-list-tile>
                    </v-list>
                  </v-card>
                </v-menu>
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
    <pagination
      :total="userPaginate.totalPage"
      :current-page="userPaginate.currentPage"
      :row-per-page="userPaginate.rowPerPage"
      @onPageChange="onPageChange"
    />
    <addUserModal ref="modalAddUser"/>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="modalConfirmTypeSelected == modalConfirmType.DELETE_USER ? onDeleteUser() : onResetPassword()"
    />
    <!-- <userDetailModal ref="userDetailModal" /> -->
    <loadingBar :is-loading="isLoading" />
    <selectInfoToPrintModal
      ref="selectInfoToPrintModal"
      @receivedInfoToShow="onPrintProductSelected"/>
    <printProductItemInfo
      ref="printProductItemInfo"
      @productPerRow="printProductItem"/>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import GenderType from 'enum/genderType'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
import ToastType from 'enum/toastType'
import AddUserModal from 'Components/AddUserModal'
import Avatar from 'vue-avatar'
import ConfirmModal from 'Components/ConfirmModal'
import stringUtils from 'utils/stringUtils'
import RoleType from 'enum/roleType'
import SelectInfoToPrintModal from './SelectInfoToPrintModal'
import printProductItemInfo from './PrintProductItemInfo'
export default {
  components: {
    addUserModal: AddUserModal,
    Avatar,
    ConfirmModal,
    SelectInfoToPrintModal,
    printProductItemInfo
  },
  data: () => ({
    userHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      // {
      //   sortable: false,
      //   text: 'common.logo',
      //   value: 'avatar'
      // },
      {
        sortable: false,
        text: 'product.name',
        value: 'name'
      },
      {
        sortable: false,
        text: 'product.productPoint',
        value: 'productPoint'
      },
      {
        sortable: false,
        text: 'product.description',
        value: 'description'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    productList: [],
    userPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    productSelected: {
      id: null
    },
    rolesSelected: [],
    isUpdate: true,
    dobDefault: null,
    menu: false,
    isShowUserDetail: true,
    confirmModalTitle: '',
    selected: [],
    userSearch: {
      searchText: null
    },
    gender: stringUtils.GenderList,
    genderList: null,
    isLoading: false,
    isActive: false,
    modalConfirmType: {
      DELETE_USER: 1,
      FORGOT_PASSWORD_USER: 2
    },
    modalConfirmTypeSelected: null,
    userForgotPassSelected: null,
    moveActionList: [
      {
        value: 1,
        text: 'questionAndAnswer.orderPriority.moveToFirst',
        action: 1
      },
      { value: 2, text: 'questionAndAnswer.orderPriority.moveToUp', action: 3 },
      {
        value: 3,
        text: 'questionAndAnswer.orderPriority.moveToDown',
        action: 4
      },
      {
        value: 4,
        text: 'questionAndAnswer.orderPriority.moveToLast',
        action: 2
      }
    ],
    printProductSelect: null
  }),
  computed: {
    ...mapGetters(['GET_SNACK_BAR', 'GET_PRODUCT_LIST_DATA', 'GET_CURRENT_ACCOUNT'])
  },
  watch: {
    GET_PRODUCT_LIST_DATA () {
      this.userPaginate.currentPage = this.GET_PRODUCT_LIST_DATA.paginate.currentPage
      this.userPaginate.totalPage = this.GET_PRODUCT_LIST_DATA.paginate.totalPage
      this.userPaginate.rowPerPage = this.GET_PRODUCT_LIST_DATA.paginate.perPage
      let productList = this.GET_PRODUCT_LIST_DATA.results
      this.productList = []
      for (let i = 0, size = productList.length; i < size; i++) {
        let userObj = {
          stt: i + 1 + (this.GET_PRODUCT_LIST_DATA.paginate.currentPage - 1) * this.GET_PRODUCT_LIST_DATA.paginate.pageSize,
          id: productList[i].id,
          name: productList[i].name,
          img: productList[i].image_url,
          productPoint: parseInt(productList[i].points),
          description: productList[i].description !== null ? functionUtils.spliceString(productList[i].description, 150) : null,
          isShowTool: false,
          isLoading: false,
          code: productList[i].code
        }
        this.productList.push(userObj)
      }
      this.isLoading = false
    }
  },
  created () {
    this.getProductList()
  },
  methods: {
    /**
     * Show modal select info
     */
    onShowModalSelectInfo: function () {
      this.$refs.selectInfoToPrintModal.onShowModal()
    },
    /**
     * Print product selected
     */
    onPrintProductSelected: function (showInfo) {
      let productIds = []
      for (let i = 0, size = this.selected.length; i < size; i++) {
        productIds.push(this.selected[i].id)
      }
      let routeData = this.$router.resolve({
        path: 'prints-qr-code-product', query: { productIds: JSON.stringify(productIds), showInfo: JSON.stringify(showInfo) }
      })
      window.open(routeData.href, '_blank')
    },
    /**
     * Show modal reset password
     */
    onShowModalResetPassword: function (user) {
      this.userForgotPassSelected = user
      this.confirmModalTitle = this.$t('forgotPassword.confirmResetPassword')
      this.modalConfirmTypeSelected = this.modalConfirmType.FORGOT_PASSWORD_USER
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Reset password
     */
    onResetPassword: function () {
      let filter = {
        email: this.userForgotPassSelected.email,
        // fromDomainType: this.userForgotPassSelected.numberOfEntity !== 0 ? LoginFromType.ADMIN : LoginFromType.CUSTOMER,
        domainWithBasePath: this.userForgotPassSelected.numberOfEntity !== 0 ? stringUtils.DOMAIN_BASE_PATH_ADMIN : stringUtils.CUSTOMER_URL
      }
      this.userForgotPassSelected.isLoading = true
      this.SEND_EMAIL_FORGOT_PASSWORD(filter).then(
        function () {
          this.userForgotPassSelected.isLoading = false
          this.ON_SHOW_TOAST({
            'message': this.$t('forgotPassword.pleaseCheckEmailToChangePassword'),
            styleType: ToastType.SUCCESS
          })
        }.bind(this)
      ).catch(
        function () {
          this.userForgotPassSelected.isLoading = false
          this.ON_SHOW_TOAST({
            'message': this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    /**
     * Get entity role
     */
    getEntityRole: function (roles) {
      let roleStr = ''
      for (let i = 0, size = roles.length; i < size; i++) {
        roleStr += roles[i].name + (i + 1 < size ? ', ' : '')
      }
      return roleStr
    },
    /**
     * Get system role
     */
    getSystemRole: function (roles) {
      let roleStr = ''
      for (let i = 0, size = roles.length; i < size; i++) {
        roleStr += roles[i].name + (i + 1 < size ? ', ' : '')
      }
      return roleStr
    },
    /**
     * Hover email
     */
    onHoverEmail: function (userItem) {
      userItem.isShowTool = true
    },
    /**
     * Leave email
     */
    onLeaveEmail: function (userItem) {
      userItem.isShowTool = false
    },
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.hasSystemRole(RoleType.ADMIN, this.GET_CURRENT_ACCOUNT)
    },
    isModerator: function () {
      return functionUtils.hasSystemRole(RoleType.MODERATOR, this.GET_CURRENT_ACCOUNT)
    },
    // Check Accountant
    isAccountant: function () {
      return functionUtils.hasSystemRole(RoleType.ACCOUNTANT, this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Active user
     */
    onActiveUser: function (userId) {
      this.ACTIVE_USER({ id: userId }).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.isLoading = true
          this.getProductList()
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('login.somethingWentWrong'),
            'styleType': ToastType.ERROR
          })
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Show modal view detail user
     */
    onShowViewProduct: function (item) {
      this.$router.push({
        name: 'product.productDetail',
        params: {
          id: item.id
        }
      })
    },
    /**
     * Search
     */
    onSearch: function (event) {
      event.preventDefault()
      this.getProductList()
    },
    /**
     * Has action
     */
    hasAction: function (metaCode) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaCode)
    },
    /**
     * Show modal add user
     */
    onAddNewProduct: function () {
      this.$router.push({
        name: 'product.productDetail',
        params: {
          id: 0
        }
      })
    },
    /**
     * Show modal delete user
     */
    onShowDeleteProduct: function (productId) {
      this.productSelected.id = productId
      this.confirmModalTitle = this.$t('product.confirmDeleteProductTitle')
      this.modalConfirmTypeSelected = this.modalConfirmType.DELETE_USER
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Delete user
     */
    onDeleteUser: function () {
      let filter = {
        params: {
          entityId: sessionStorage.getItem('entityId')
        }
      }
      this.DELETE_PRODUCT({ id: this.productSelected.id, ...filter }).then(
        function () {
          this.productSelected.id = null
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.getProductList()
        }.bind(this)
      )
    },
    /**
     * Get date of birth
     */
    getDateOfBirth: function (dob) {
      if (!functionUtils.isNull(dob)) {
        return dateUtils.formatDate(dob, dateUtils.STATIC_FORMAT_DATE_TIME_ZONE, dateUtils.STATIC_FORMAT_DATE)
      }
      return null
    },
    /**
     * Get gender
     */
    getGender: function (gender) {
      if (gender === GenderType.MALE) {
        return 'userProfile.genderType.male'
      } else if (gender === GenderType.FEMALE) {
        return 'userProfile.genderType.female'
      } else {
        return 'userProfile.genderType.other'
      }
    },
    /**
     * Get user list
     */
    getProductList: function () {
      this.isLoading = true
      let filter = {
        params: {
          page: this.userPaginate.currentPage,
          name: this.userSearch.searchText
        }
      }
      this.GET_PRODUCT_LIST(filter).then(
        function () {}
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.userPaginate.currentPage = page
      this.getProductList()
    },
    // update priority
    clickMoveQuestion: function (actionValue, item) {
      this.updateQuestionPriority(actionValue, item)
    },
    updateQuestionPriority: function (actionValue, item) {
      let data = {
        first_question_id: item.id,
        action_type: actionValue.action
      }
      this.UPDATE_PRIORITY_PRODUCT(data)
        .then(
          function () {
            this.getProductList()
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    onShowPrintProduct: function (data) {
      //
      this.printProductSelect = data
      this.$refs.printProductItemInfo.onShowModal()
    },
    printProductItem: function (data) {
      let routeData = this.$router.resolve({
        path: 'prints-product-item', query: { itemPerRow: data.printProductInfo.itemPerRow, qr: this.printProductSelect.code, name: this.printProductSelect.name }
      })
      window.open(routeData.href, '_blank')
    },
    ...mapActions([
      'GET_PRODUCT_LIST',
      'UPDATE_USER_PROFILE',
      'DELETE_PRODUCT',
      'ON_SHOW_TOAST',
      'ACTIVE_USER',
      'SEND_EMAIL_FORGOT_PASSWORD',
      'UPDATE_PRIORITY_PRODUCT'])
  }
}
</script>
<style lang="scss" scoped>
@import "../../styles/material-dashboard/_colors.scss";
/deep/ .test-primary {
  background-color: #c7c7c7c7 !important;
}
/deep/ .test-primary:hover {
  background-color: #c7c7c7c7 !important;
}
/deep/ .theme--light.v-list {
  background-color: transparent;
}
.border-style {
  border-bottom: 2px solid $border-color;
}
.cursor-pointer {
  cursor: pointer !important;
}
.hover-detail-text:hover {
  border-bottom: 1px solid $border-color;
}
.hover-delete-text:hover {
  border-bottom: 1px solid $border-color;
}
.hover-active-text:hover {
  border-bottom: 1px solid $border-color;
}
//
.product-img-class {
  max-width: 60px;
  max-height: 60px;
}
.product-img-div {
  width: 60px;
  height: 60px;
}
</style>
