<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="$t('product.productInfo')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout wrap>
              <v-flex
                xs12
                sm12
                md12>
                {{ $t('product.numberOfItemPerRow') }}: <span class="font-weight-bold">{{ printProductInfo.itemPerRow }} </span>
              </v-flex>
              <v-flex
                xs12
                sm12
                md12>
                <v-slider
                  v-model="printProductInfo.itemPerRow"
                  :label="$t('product.quantity')"
                  color="green"
                  hint="Be honest"
                  min="2"
                  max="15"
                  thumb-label
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      valid: false,
      isShowModal: false,
      printProductInfo: {
        itemPerRow: 10
      }
    }
  },
  methods: {
    /**
     * Show modal confirm
     */
    onConfirm: function () {
      this.$emit('productPerRow', { printProductInfo: this.printProductInfo })
      this.isShowModal = false
    },
    onShowModal: function () {
      this.isShowModal = true
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    ...mapActions(['ON_SHOW_TOAST'])
  }

}
</script>
<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
